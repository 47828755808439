import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const getUserBy = async (query) => {
	return axios.get(`${API_URL_DEFAULT}user/by-dept-div-pos`, {
		headers: await authHeader(),
		params: query,
	});
};

const getAllDepartments = async () => {
	return axios.get(`${API_URL_DEFAULT}user/all-department`, {
		headers: await authHeader(),
	});
};

const getAllUsers = async (query) => {
	return axios.get(`${API_URL_DEFAULT}user/all-user`, {
		headers: await authHeader(),
		params: query,
	});
};

export default { getUserBy, getAllDepartments, getAllUsers };
