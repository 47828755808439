import GenrealSettingsService from '../services/general-settings.service';

const get5RSettings = () => {
	return GenrealSettingsService.get5RSettings().then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const getAccessMaster = (payload) => {
	return GenrealSettingsService.getAccessMaster(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { get5RSettings, getAccessMaster };
