import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}data/form-audit`, payload, {
		headers: await authHeader(),
	});
};

const read = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}data/form-audit`, {
		headers: await authHeader(),
		params: payload,
	});
};

const update = async (payload) => {
	return axios.put(`${API_URL_DEFAULT}data/form-audit`, payload, {
		headers: await authHeader(),
	});
};

const destroy = async (payload) => {
	return axios.delete(`${API_URL_DEFAULT}data/form-audit`, {
		headers: await authHeader(),
		data: payload,
	});
};

export default { create, read, update, destroy };
