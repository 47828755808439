import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useCookie, useEffectOnce, useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import jwtDecode from 'jwt-decode';
import { TourProvider } from '@reactour/tour';
import Swal from 'sweetalert2';
import ThemeContext from '../contexts/themeContext';

import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import Modal, { ModalBody } from '../components/bootstrap/Modal';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { cookieNameGenerator, getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import Aside from '../layout/Aside/Aside';
import AuthModule from '../modules/AuthModule';

const accessToken = localStorage.getItem('accessToken') || null;
const appToken = localStorage.getItem('appToken') || null;
const IS_SSO = process.env.REACT_APP_SSO == 'true' || false;
const SSO_KEY = process.env.REACT_APP_SSO_KEY || null;
const SSO_HOMEPAGE = process.env.REACT_APP_SSO_HOMEPAGE || null;

const App = () => {
	const [cookie] = useCookie([cookieNameGenerator('ssocookies')]);
	const [cookieAcc] = useCookie([cookieNameGenerator('ssoaccount')]);
	const [cookiePhone] = useCookie([cookieNameGenerator('ssophonenumber')]);
	getOS();
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	const testJSON = (text) => {
		if (typeof text !== 'string') {
			return false;
		}
		try {
			JSON.parse(text);
			return true;
		} catch (error) {
			return false;
		}
	};
	useEffectOnce(() => {
		const isSSOLogin = () => {
			try {
				if (!appToken) {
					AuthModule.generateToken().then(()=>{
						return window.location.reload();
					});
				}
				const isEmailSSO = cookieAcc ? window.atob(cookieAcc) : null;
				const isHPSSO = cookiePhone ? window.atob(cookiePhone) : null;
				if (IS_SSO) {
					if (!cookie) {
						AuthModule.goONELNKHomapage();
						window.location.href = SSO_HOMEPAGE;
						return SSO_HOMEPAGE;
					}
					const decryptCookieSSO = window.atob(cookie);
					if (!decryptCookieSSO) {
						AuthModule.goONELNKHomapage();
						window.location.href = SSO_HOMEPAGE;
						return SSO_HOMEPAGE;
					}
					const tokenAccSSO = testJSON(decryptCookieSSO)
						? JSON.parse(decryptCookieSSO)
						: null;
					if (!tokenAccSSO?.sso_key) {
						AuthModule.goONELNKHomapage();
						window.location.href = SSO_HOMEPAGE;
						return SSO_HOMEPAGE;
					}
					if (tokenAccSSO?.sso_key && tokenAccSSO?.sso_key !== SSO_KEY) {
						AuthModule.goONELNKHomapage();
						window.location.href = SSO_HOMEPAGE;
						return SSO_HOMEPAGE;
					}

					const decodeToken = tokenAccSSO ? jwtDecode(tokenAccSSO?.accessToken) : null;
					//  isEmailSSo then get accessToken
					if (isEmailSSO && decodeToken && !accessToken) {
						setLoadingSubmit(true);
						const parseEmail = JSON.parse(isEmailSSO);
						const newPayload = {
							name: parseEmail?.name,
							email: parseEmail?.idTokenClaims?.preferred_username,
							account_id: parseEmail?.localAccountId,
							username: parseEmail?.localAccountId,
						};
						return AuthModule.loginSSO(newPayload)
							.then(async (res) => {
								const verifyToken = jwtDecode(res.accessToken);
								localStorage.setItem('accessToken', JSON.stringify(res));
								let role = 'Guest User';
								if (verifyToken.details.hris_org_tree.current_person) {
									if (res?.role) {
										role = res.role;
									} else {
										role = ''.concat(
											verifyToken.details.hris_org_tree.current_person
												.nama_department,
											' ',
											verifyToken.details.hris_org_tree.current_person
												.nama_posisi,
										);
									}
									localStorage.setItem('roles', JSON.stringify(role));

									// get menu
									await AuthModule.getMenu(role).then(() => {
										window.location.reload();
									});
								}
							})
							.finally(() => {
								setLoadingSubmit(false);
							});
					} // login by phone
					if (
						!isEmailSSO &&
						isHPSSO &&
						decodeToken &&
						!accessToken &&
						tokenAccSSO?.sso_key
					) {
						setLoadingSubmit(true);

						const parseEmail = JSON.parse(isHPSSO);
						return AuthModule.loginPhone(parseEmail)
							.then(async (res) => {
								const verifyToken = jwtDecode(res.accessToken);
								localStorage.setItem('accessToken', JSON.stringify(res));
								let role = 'Guest User';
								if (verifyToken.details.hris_org_tree.current_person) {
									if (res?.role) {
										role = res.role;
									} else {
										role = ''.concat(
											verifyToken.details.hris_org_tree.current_person
												.nama_department,
											' ',
											verifyToken.details.hris_org_tree.current_person
												.nama_posisi,
										);
									}
									localStorage.setItem('roles', JSON.stringify(role));

									// get menu
									await AuthModule.getMenu(role).then(() => {
										window.location.reload();
									});
								}
							})
							.catch((err) => {
								Swal.fire({
									heightAuto: false,
									title: 'Warning!',
									text: err,
									icon: 'info',
								});
							})
							.finally(() => {
								setLoadingSubmit(false);
							});
					}
					//  !isEmailSSo && !isHPSSO then get accessToken
					if (
						!isEmailSSO &&
						!isHPSSO &&
						decodeToken &&
						!accessToken &&
						tokenAccSSO?.sso_key
					) {
						setLoadingSubmit(true);
						const accTokenParse = jwtDecode(tokenAccSSO.accessToken);
						return AuthModule.login(
							accTokenParse.username.toUpperCase(),
							accTokenParse.password,
						)
							.then(async (res) => {
								const verifyToken = jwtDecode(res.accessToken);
								localStorage.setItem('accessToken', JSON.stringify(res));
								let role = 'Guest User';
								if (verifyToken.details.hris_org_tree.current_person) {
									if (res?.role) {
										role = res.role;
									} else {
										role = ''.concat(
											verifyToken.details.hris_org_tree.current_person
												.nama_department,
											' ',
											verifyToken.details.hris_org_tree.current_person
												.nama_posisi,
										);
									}
									localStorage.setItem('roles', JSON.stringify(role));

									// get menu
									await AuthModule.getMenu(role).then(() => {
										window.location.reload();
									});
								}
							})
							.finally(() => {
								setLoadingSubmit(false);
							});
					}
				}
				return isEmailSSO;
			} catch (err) {
				return Swal.fire({
					heightAuto: false,
					title: 'Warning!',
					text: err,
					icon: 'info',
				});
			}
		};
		isSSOLogin();
	});

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = ['login'];

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<Modal
						isOpen={loadingSubmit}
						size='sm'
						isCentered
						setIsOpen={() => {}}
						isStaticBackdrop>
						<ModalBody
							style={{ backgroundColor: '#6c5dd3', color: 'white' }}
							className='text-center'>
							<button className='btn btn-primary' type='button' disabled>
								<span
									className='spinner-grow spinner-grow-sm'
									role='status'
									aria-hidden='true'
								/>
								&nbsp;
								<span
									className='spinner-grow spinner-grow-sm'
									role='status'
									aria-hidden='true'
								/>
								&nbsp;
								<span
									className='spinner-grow spinner-grow-sm'
									role='status'
									aria-hidden='true'
								/>
								&nbsp;
								<span className='sr-only'>Loading...</span>
							</button>
						</ModalBody>
					</Modal>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
							overflow: fullScreenStatus && 'scroll',
						}}>
						<Routes>
							{withOutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							<Route path='*' element={accessToken ? <Aside /> : null} />
						</Routes>
						<Wrapper />
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
