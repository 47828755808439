import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import Card, { CardBody } from '../../components/bootstrap/Card';
import { getRequester, isEmptyArray } from '../../helpers/helpers';
import DatePicker from '../../components/custom/DatePicker';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import FormAuditModule from '../../modules/master/FormAuditModule';
import Select from '../../components/custom/Select';
import Input from '../../components/bootstrap/forms/Input';
import UserModule from '../../modules/UserModule';
import GeneralSettingsModule from '../../modules/GeneralSettingsModule';
import ReportModule from '../../modules/audit/ReportModule';
import Chart from '../../components/extras/Chart';
import Colors from './chart_colors';
import Label from '../../components/bootstrap/forms/Label';

const DashboardChart = () => {
	const { department, position_code, position_name } = getRequester();
	const { darkModeStatus } = useDarkMode();

	const [selectedAudit, setSelectedAudit] = useState({ value: '5r', label: '5R' });
	const [selectedAuditTitleChart, setSelectedAuditTitleChart] = useState(null);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [listReport, setListReport] = useState(null);
	const [listAudit, setListAudit] = useState(null);
	const [listDepartment, setListDepartment] = useState([]);
	const [monthStart, setMonthStart] = useState(moment().subtract(2, 'months').format('YYYY-MM'));
	const [monthEnd, setMonthEnd] = useState(moment().format('YYYY-MM'));
	const [general, setGeneral] = useState({
		allowed: false,
		message: 'You are not allowed to access this page. Please Contact Admin!',
	});
	const [isReady, setIsReady] = useState(false);

	const fetchDataFormAudit = async () => {
		const query = { showAll: true };
		return FormAuditModule.read(query)
			.then((response) => {
				const new_list = response?.foundData?.map((e) => ({
					value: e?.form_audit_code,
					label: e?.form_audit_name,
				}));
				setListAudit(new_list);
			})
			.catch(() => {
				setListAudit([]);
			})
			.finally(() => {});
	};

	const fetchDataDepartment = async () => {
		const query = {};
		return UserModule.getAllDepartments(query)
			.then((response) => {
				const new_response = response?.map((item) => {
					return { value: item.department, label: item.department };
				});
				setListDepartment(new_response);
			})
			.catch(() => {
				setListDepartment([]);
			})
			.finally(() => {});
	};

	const fetchAdmin = async () => {
		const query = { position_code, position_name };

		return GeneralSettingsModule.getAccessMaster(query)
			.then((response) => {
				if (!response?.allowed) {
					setSelectedDepartment({
						value: department,
						label: department,
					});
				}

				setGeneral((e) => ({
					...e,
					allowed: response?.allowed ?? false,
					message:
						response?.message ??
						'You are not allowed to access this page. Please Contact Admin!',
				}));
			})
			.catch(() => {
				setGeneral((e) => ({ ...e, allowed: false }));
			})
			.finally(() => {});
	};

	const handleFilter = async () => {
		const addDayMonthStart = `${monthStart}-01`;
		const addDayMonthEnd = `${monthEnd}-01`;
		const monthDifference = moment(new Date(addDayMonthEnd)).diff(
			new Date(addDayMonthStart),
			'months',
			true,
		);

		if (Date.parse(addDayMonthStart) >= Date.parse(addDayMonthEnd)) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Month End should be greater than Month Start!',
				icon: 'warning',
			});
			return;
			// eslint-disable-next-line no-else-return
		} else if (monthDifference > 11) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'maximum of 12 months!',
				icon: 'warning',
			});

			return;
		}

		const query = {
			audit: selectedAudit,
			month_start: monthStart,
			month_end: monthEnd,
			department: selectedDepartment,
		};

		if (selectedDepartment) {
			ReportModule.readDashboardReport(query)
				.then((response) => {
					if (Array.isArray(selectedDepartment)) {
						selectedDepartment.map((val) => {
							const index = Math.floor(Math.random() * Colors.length);
							const color = Colors[index];
							val.fillColor = color;
							return val;
						});
					} else {
						const index = Math.floor(Math.random() * Colors.length);
						selectedDepartment.fillColor = Colors[index];
					}

					response.series[0].data.map((v) => {
						v.fillColor = Array.isArray(selectedDepartment)
							? selectedDepartment.filter((d) => d.value == v.x)[0].fillColor
							: selectedDepartment.fillColor;
						return v;
					});

					setListReport(response);
					setIsReady(true);
					setSelectedAuditTitleChart(selectedAudit.label);
				})
				.catch(() => {})
				.finally(() => {});
		}
	};

	const handleReset = () => {
		setSelectedAudit({ value: '5r', label: '5R' });
		setSelectedDepartment(null);
		setListReport(null);
		setIsReady(false);
		setMonthStart(moment().subtract(2, 'months').format('YYYY-MM'));
		setMonthEnd(moment().format('YYYY-MM'));
		fetchAdmin();
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(async () => {
		fetchDataFormAudit();
		fetchDataDepartment();
		await fetchAdmin();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handleFilter();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [general]);

	return (
		<Card shadow='sm'>
			<CardBody>
				<div>
					<div className='d-flex justify-content-start'>
						<div className='m-1 col-sm-2 col-md-2'>
							<Label>Audit *</Label>
							<Select
								options={listAudit}
								defaultValue={selectedAudit}
								value={selectedAudit}
								onChange={(e) => setSelectedAudit(e)}
							/>
						</div>

						<div className='m-1 col-sm-2 col-md-2'>
							<Label>Month Start *</Label>
							<DatePicker
								dateFormat='MM/yyyy'
								placeholder='YYYY-MM'
								placeholderText='Month Start'
								showFullMonthYearPicker
								showMonthYearPicker
								value={monthStart}
								onChange={(date) => setMonthStart(date)}
								customInput={<Input type='text' className='mb-4' />}
							/>
						</div>

						<div className='m-1 col-sm-2 col-md-2'>
							<Label>Month End *</Label>
							<DatePicker
								dateFormat='MM/yyyy'
								placeholder='YYYY-MM'
								minDate={moment(monthStart).toDate()}
								maxDate={moment(monthStart).add(11, 'M').toDate()}
								showFullMonthYearPicker
								showMonthYearPicker
								value={monthEnd}
								onChange={(date) => setMonthEnd(date)}
								customInput={<Input type='text' className='mb-4' />}
							/>
						</div>

						<div className='m-1 col-sm-3 col-md-3'>
							<Label>Organization</Label>
							<Select
								options={listDepartment}
								defaultValue={selectedDepartment}
								value={selectedDepartment}
								placeholder='Select Organizations'
								onChange={(e) => setSelectedDepartment(e)}
								isMulti={general.allowed}
								isDisable={!general.allowed}
							/>
						</div>

						<div className='m-1 align-self-center'>
							<Button
								icon='Search'
								color='info'
								type='button'
								onClick={() => handleFilter()}
								isLight={darkModeStatus}>
								Filter
							</Button>
						</div>

						<div className='m-1 align-self-center'>
							<Button
								icon='Refresh'
								color='warning'
								type='button'
								onClick={() => handleReset()}
								isLight={darkModeStatus}>
								Reset
							</Button>
						</div>
					</div>
				</div>
				{isReady && !isEmptyArray(listReport.group) && (
					<div>
						<Chart
							width='100%'
							height={350}
							options={{
								chart: {
									type: 'bar',
									height: 380,
								},
								plotOptions: {
									bar: {
										dataLabels: {
											position: 'top', // top, center, bottom
										},
									},
								},
								dataLabels: {
									enabled: true,
									// eslint-disable-next-line object-shorthand
									formatter: function (val) {
										return val;
									},
									offsetY: -20,
									style: {
										fontSize: '12px',
										colors: ['#304758'],
									},
								},
								xaxis: {
									type: 'category',
									labels: {
										// eslint-disable-next-line object-shorthand
										formatter: function (val) {
											return val;
										},
									},
									group: {
										style: {
											fontSize: '10px',
											fontWeight: 700,
										},
										groups: listReport.group,
									},
								},
								title: {
									text: selectedAuditTitleChart,
									align: 'center',
									margin: 25,
								},
								tooltip: {
									x: {
										// eslint-disable-next-line object-shorthand
										formatter: function (val) {
											return val;
										},
									},
								},
							}}
							series={listReport.series}
							type='bar'
						/>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default DashboardChart;
