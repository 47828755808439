import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const get5RSettings = async () => {
	return axios.get(`${API_URL_DEFAULT}audit/general-settings/5r`, {
		headers: await authHeader(),
	});
};

const getAccessMaster = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}audit/general-settings/access-master`, {
		headers: await authHeader(),
		params: payload,
	});
};

export default { get5RSettings, getAccessMaster };
