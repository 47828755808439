import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (payload) => {
	return axios.get(`${API_URL_DEFAULT}audit/report`, {
		headers: await authHeader(),
		params: payload,
	});
};

const readDashboardReport = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}audit/report/dashboard-report`, payload, {
		headers: await authHeader(),
	});
};

const generate = async (payload) => {
	return axios.put(`${API_URL_DEFAULT}audit/report/generate`, payload, {
		headers: await authHeader(),
	});
};

const exportReport = async (payload) => {
	return axios.put(`${API_URL_DEFAULT}audit/report/export`, payload, {
		headers: await authHeader(),
	});
};

export default { read, readDashboardReport, generate, exportReport };
