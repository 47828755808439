import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/ExampleCRUD')),
};

const Master = {
	Area: lazy(() => import('../views/master/NewArea')),
	TeamAuditor: lazy(() => import('../views/master/Team')),
	TeamAuditee: lazy(() => import('../views/master/TeamAuditee')),
	FormAudit: lazy(() => import('../views/master/FormAudit')),
};

const Audit = {
	AuditReport: lazy(() => import('../views/audit/NewAuditReport')),
	Auditee: lazy(() => import('../views/audit/NewAuditee')),
	Auditor: lazy(() => import('../views/audit/NewAuditor')),
};

const contents = [
	// DUMMY
	{
		path: '/page-testing/crud',
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	// MASTER
	{
		path: '/master-data/area',
		element: <Master.Area />,
		index: 'MasterArea',
		exact: true,
	},
	{
		path: '/master-data/team-auditor',
		element: <Master.TeamAuditor />,
		index: 'MasterTeamAuditor',
		exact: true,
	},
	{
		path: '/master-data/team-auditee',
		element: <Master.TeamAuditee />,
		index: 'MasterTeamAuditee',
		exact: true,
	},
	{
		path: '/master-data/form-audit',
		element: <Master.FormAudit />,
		index: 'MasterAudit',
		exact: true,
	},
	// AUDIT
	{
		path: '/audit/report',
		element: <Audit.AuditReport />,
		index: 'AuditReport',
		exact: true,
	},
	{
		path: '/audit/auditor',
		element: <Audit.Auditor />,
		index: 'AuditAuditor',
		exact: true,
	},
	{
		path: '/audit/auditee',
		element: <Audit.Auditee />,
		index: 'AuditAuditee',
		exact: true,
	},
];
export default contents;
