import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../bootstrap/Button';
import Input from '../bootstrap/forms/Input';

const DatePicker = ({
	name,
	value,
	dateFormat,
	invalidFeedback,
	className,
	placeholder,
	isValid,
	showFullMonthYearPicker,
	showMonthYearPicker,
	showYearPicker,
	onChange,
	isReadOnly,
	buttonComponent,
	...props
}) => {
	// const { darkModeStatus } =
	if (isReadOnly) {
		return <Input disabled value={value ?? placeholder} />;
	}

	return (
		<>
			<ReactDatePicker
				selected={(value && moment(value).toDate()) || null}
				onChange={(e) => onChange(moment(e).format(placeholder))}
				placeholderText={placeholder}
				dateFormat={dateFormat}
				className={
					// eslint-disable-next-line no-nested-ternary
					className && !invalidFeedback
						? `form-control form-control-md ${className}`
						: !invalidFeedback && isValid
						? 'form-control form-control-md'
						: 'form-control form-control-md is-invalid'
				}
				ariaInvalid
				disabledKeyboardNavigation
				customInput={
					buttonComponent ?? (
						<Button
							style={{
								display: 'flex',
								justifyContent: 'start',
								color: value ? '' : 'grey',
							}}>
							{value ?? placeholder}
						</Button>
					)
				}
				showFullMonthYearPicker={showFullMonthYearPicker}
				showMonthYearPicker={showMonthYearPicker}
				showYearPicker={showYearPicker}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
			/>
			{invalidFeedback && !isValid && (
				<div
					style={{
						width: '100%',
						marginTop: '.25rem',
						fontSize: '80%',
						color: ' #dc3545',
					}}>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

DatePicker.propTypes = {
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	invalidFeedback: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	dateFormat: PropTypes.string,
	isValid: PropTypes.bool,
	showFullMonthYearPicker: PropTypes.bool,
	showMonthYearPicker: PropTypes.bool,
	showYearPicker: PropTypes.bool,
	isReadOnly: PropTypes.bool,
	buttonComponent: PropTypes.oneOfType([PropTypes.object]),
};
DatePicker.defaultProps = {
	name: null,
	value: moment().format('YYYY-MM-DD'),
	onChange: () => {},
	invalidFeedback: null,
	className: null,
	placeholder: 'YYYY-MM-DD',
	dateFormat: 'yyyy-MM-dd',
	isValid: true,
	showFullMonthYearPicker: false,
	showMonthYearPicker: false,
	showYearPicker: false,
	isReadOnly: false,
	buttonComponent: null,
};

export default DatePicker;
