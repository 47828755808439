import React from 'react';
import classNames from 'classnames';
import Header from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { getRequester } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';

const PageLayoutHeader = () => {
	const { person_name } = getRequester();
	const { darkModeStatus } = useDarkMode();

	return (
		<Header className={classNames(window.innerWidth < 768 ? 'py-5' : 'py-5')}>
			<div className={classNames('header-left', 'col-md')}>
				<div className={classNames('d-flex', 'align-items-center')}>
					<div className={classNames('row', 'g-4')}>
						<div className={classNames('col-md-auto')}>
							<div
								className={classNames(
									'fs-4',
									'fw-bold',
									darkModeStatus ? '' : 'text-dark',
								)}>
								<div className='d-flex flex-row align-items-center'>
									{process.env.REACT_APP_ENV &&
										process.env.REACT_APP_ENV !== 'production' && (
											<div className='blink'>
												{process.env.REACT_APP_ENV.toUpperCase()}
											</div>
										)}
									Hi, {person_name}!
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CommonHeaderRight />
		</Header>
	);
};

export default PageLayoutHeader;
