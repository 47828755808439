import ReportService from '../../services/audit/report.service';

const read = (payload) => {
	return ReportService.read(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const readDashboardReport = (payload) => {
	return ReportService.readDashboardReport(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const generate = (payload) => {
	return ReportService.generate(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const exportReport = (payload) => {
	return ReportService.exportReport(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { read, readDashboardReport, generate, exportReport };
