const colors = [
	'#F3B415',
	'#F27036',
	'#663F59',
	'#6A6E94',
	'#4E88B4',
	'#00A7C6',
	'#18D8D8',
	'#A9D794',
	'#46AF78',
	'#A93F55',
	'#8C5E58',
	'#2176FF',
	'#33A1FD',
	'#7A918D',
	'#BAFF29',
];

export default colors;
